<template>
  <div v-if="address" class="grid-x grid-padding-x">
      <div class="cell large-6 medium-6 small-6">
        <md-field :class="getValidationClass('street')">
          <label :for="getStreetId">Straße</label>
          <md-input :name="getStreetId" :id="getStreetId" autocomplete="off" v-model="address.street"
                    :disabled="sending"/>
          <span class="md-error" v-if="!$v.address.street.required">Straße ist notwendig</span>
        </md-field>
      </div>
      <div class="cell large-6 medium-6 small-6">
        <md-field :class="getValidationClass('streetNumber')">
          <label :for="getStreetNumberId">Hausnummer</label>
          <md-input :name="getStreetNumberId" :id="getStreetNumberId" autocomplete="off"
                    v-model="address.streetNumber"
                    :disabled="sending"/>
          <span class="md-error" v-if="!$v.address.streetNumber.required">Hausnummer ist notwendig</span>
        </md-field>
      </div>
      <div class="cell large-6 medium-6 small-6">
        <md-field :class="getValidationClass('zipcode')">
          <label :for="getZipCodeId">Postleitzahl</label>
          <md-input type="number" :name="getZipCodeId" :id="getZipCodeId" autocomplete="off" v-model="address.zipcode"
                    :disabled="sending"/>
          <span class="md-error" v-if="!$v.address.zipcode.required">Postleitzahl ist notwendig</span>
          <span class="md-error"
                v-else-if="!$v.address.zipcode.minLength">Postleitzahl muss genau 5 Zahlen lang sein</span>
          <span class="md-error"
                v-else-if="!$v.address.zipcode.maxLength">Postleitzahl muss genau 5 Zahlen lang sein</span>
        </md-field>
      </div>
      <div class="cell large-6 medium-6 small-6">
        <md-field :class="getValidationClass('placeOfResidence')">
          <label :for="getPlaceOfResidenceId">Ort</label>
          <md-input :name="getPlaceOfResidenceId" :id="getPlaceOfResidenceId" autocomplete="off"
                    v-model="address.placeOfResidence" :disabled="sending"/>
          <span class="md-error" v-if="!$v.address.placeOfResidence.required">Standort ist notwendig</span>
        </md-field>
      </div>
      <div v-if="labels && labels.country && labels.availableCountryEntries" class="cell large-6 medium-6 small-6">
        <div v-if="address.country" style="display: none">{{address.selectedCountry = labels.country[address.country]}}</div>
        <md-autocomplete id="countries-id" v-model="address.selectedCountry" :md-options="countries"
                         :class="getValidationClass('selectedCountry')"
                         @md-changed="getCountries" @md-opened="getCountries"
                         @md-selected="selectCountry" value="">
          <label :for="getAutocompleteTemplateId">Land</label>

          <template :name="getAutocompleteTemplateId" :id="getAutocompleteTemplateId" slot="md-autocomplete-item" slot-scope="{ item, term }">
            <md-highlight-text :md-term="term">{{ item.name }}</md-highlight-text>
          </template>

          <template slot="md-autocomplete-empty" slot-scope="{ term }">
            "{{ term }}" nicht gefunden
          </template>

          <span class="md-error" v-if="!$v.address.selectedCountry.required">Land ist notwendig</span>
        </md-autocomplete>
      </div>
    </div>
</template>


<script>
  import jQuery from 'jquery';
  import {validationMixin} from 'vuelidate'
  import {
    required,
    minLength,
    maxLength,
  } from 'vuelidate/lib/validators'

  export default {
    name: 'AddressForm',
    mixins: [validationMixin],

    props: {
      address: {
        required: true
      },
      sending: {
        required: true
      }
    },

    created() {
      this.initSyncHandler(true);
    },

    beforeDestroy() {
      this.initSyncHandler(false)
    },

    data: () => ({
      invalid: true,
      countries: [],
      value: null,
      rand: Math.floor(Math.random() * Math.floor(999999)),
    }),

    validations: {
      address: {
        street: {
          required,
        },
        streetNumber: {
          required,
        },
        zipcode: {
          required,
          minLength: minLength(5),
          maxLength: maxLength(5),
        },
        placeOfResidence: {
          required,
        },
        selectedCountry: {
          required,
        },
      },
    },

    methods: {

      initSyncHandler(init) {
        if (init) {
          setTimeout(() => {
            jQuery('#countries-id').children('.md-menu').children('input').bind('focus', () => {
              setTimeout(() => jQuery('#countries-id').children('.md-menu').children('input').trigger('click'), 250);
            });
          }, 500);
        } else {
          jQuery('#countries-id').children('input').unbind('focus');
        }
      },

      getValidationClass(fieldName) {
        let field = this.$v.address[fieldName];

        if (field) {
          return {
            'md-invalid': field.$invalid && field.$dirty
          }
        }
      },

      validateAddress() {
        this.$v.$touch();
        this.invalid = this.$v.$invalid;
      },

      getCountries(searchTerm) {
        if (!searchTerm) {
          this.address.country = '';
        }
        this.countries = new Promise(resolve => {
          window.setTimeout(() => {
            if (!searchTerm) {
              resolve(this.labels.availableCountryEntries)
            } else {
              const term = searchTerm.toLowerCase();
              resolve(this.labels.availableCountryEntries.filter(({name}) => name.toLowerCase().includes(term)))
            }
          }, 5)
        })
      },

      selectCountry(country) {
        this.address.selectedCountry = country.name;
        this.address.country = country.id;
      }
    },

    computed: {

      getStreetId() {
        return 'street_' + this.rand;
      },

      getStreetNumberId() {
        return 'streetNumber_' + this.rand;
      },

      getZipCodeId() {
        return 'zipCode_' + this.rand;
      },

      getPlaceOfResidenceId() {
        return 'placeOfResidence_' + this.rand;
      },

      getAutocompleteTemplateId() {
        return 'autocompleteTemplateId_' + this.rand;
      },

      labels() {
        return this.$store.getters.labels
      },
    },
  };
</script>

<style lang="scss">

  .md-menu-content {
    z-index: 25 !important;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
</style>