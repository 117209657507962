<template>
  <div>
    <div class="grid-x grid-padding-x">
      <div v-if="showPriceCalculator" class="cell callout success"><h3><i class="fi-euro"></i>&nbsp;&nbsp;&nbsp;Module & Preise</h3></div>
      <div v-else class="cell callout success"><h3><md-icon style="color: black; font-size: 2rem!important;">how_to_reg</md-icon>&nbsp;&nbsp;&nbsp;Registrierung</h3></div>
    </div>

    <div class="grid-x grid-padding-x grid-padding-y">
      <div v-if="webAppConfig.soseRegistrationDisabled" class="cell" style="padding-right: 35px;">
        <md-card style="width: 100%">
          <md-card-header>
            <div class="md-title">
              <p style="font-weight: 300">
                Lieber SoSE-Nutzer,
                <br><br>
                derzeit steht das System aufgrund von aktuellen <b style="color: seagreen">Wartungsarbeiten</b> nicht zur Verfügung.
                <br>
                Sobald diese beendet sind, können Sie hier mit Ihrer Registrierung fortfahren.
                <br><br>
                Vielen Dank für Ihr Verständnis.
                <br>
                ai42 Team
              </p>
              <br><md-progress-bar md-mode="buffer"/>
            </div>
          </md-card-header>
        </md-card>
      </div>
      <div v-else class="cell" style="padding-right: 35px;">
        <md-card style="width: 100%">
          <md-card-header v-if="!showPriceCalculator">
            <div v-if="concludeContractActive || testLicenseActive" class="grid-x grid-padding-x">
              <div class="cell large-8">
                <div class="md-title">
                  <span>Software für Soziale Einrichtungen (SoSE)&nbsp;&nbsp;&nbsp;<strong>Registrierung</strong></span>
                </div>
                <br>
                <div v-if="!contractConcluded && !testAccountRequested">
                  <div v-if="friendsAndFamiliyActive" class="md-title-textarea text-justify">
                    Es freut uns, dass Sie an unserer <strong>Friends & Family</strong> Test-Phase teilnehmen möchten.
                    Wir schätzen Ihr Interesse und freuen uns über Ihre Teilnahme. Ihr Feedback ist uns sehr wichtig!<br><br>
                    Bitte nutzen Sie daher jederzeit unseren <span>Feedback Button</span>
                    <md-button class="md-fab md-accent md-mini" style="margin-left: 5px; top: -7px; border-radius: 50%;">
                      <md-icon style="font-size: 1rem !important;">feedback</md-icon>
                      <md-tooltip>Die Feedback-Funktion ist im angemeldeten Bereich verfügbar.</md-tooltip>
                    </md-button>
                    in der Navigationsleiste.<br><br>
                    <b>Hinweis</b><br>
                    Da uns Software-Qualität ein hohes Anliegen ist, wird der Abschluss eines Service-Vertrags erst nach
                    Beendigung unserer <strong>Friends & Family</strong> Test-Phase möglich sein.
                    Ab <span><b>September 2020</b></span> freuen wir uns, Sie als Kunden bei uns begrüßen zu dürfen.<br>
                  </div>
                  <div v-else class="md-title-textarea text-justify">
                    Es freut uns, dass Sie SoSE für Ihre Einrichtung nutzen möchten. Sie sind bereits von SoSE überzeugt?
                    Dann füllen Sie einfach in <strong>3 Schritten</strong> dieses Formular vollständig aus.
                    Gleich danach kann es losgehen!
                  </div>
                  <br>
                  <div class="md-subtitle-textarea text-justify">
                    Eine Registrierung ist nur zulässig für Unternehmer (im Sinne §14 BGB) und Vereine, die Kindertageseinrichtungen (oder vergleichbare Einrichtungen) oder Träger sind. Eine Registrierung als Verbraucher (im Sinne §13 BGB) ist unzulässig.
                  </div>
                  <br>
                  <div v-if="!organization.testPhaseActive && testLicenseActive && !friendsAndFamiliyActive" class="md-title-textarea text-justify">
                    <span>Alternativ können Sie <b>SoSE</b> auch mit einer Test-Lizenz erst kennenlernen.</span>
                    <br>
                    <md-checkbox class="md-primary" v-model="demand.testAccountDemand" @change="onToggleTestLicense()">
                      <span style="font-size: 1.2rem"><strong>Test-Lizenz</strong> anfordern</span>
                    </md-checkbox>
                    <br><br>
                    <span><b>WICHTIGER HINWEIS</b><br>Wenn Sie schon einen Testzugang haben, so <strong>ist Ihre Einrichtung bereits registriert</strong>.
                      Bitte melden Sie sich in diesem Fall erst mit diesem Zugang an, um anschließend ihr Bundle zu konfigurieren und zu bestellen.</span>
                    <br>
                  </div>
                </div>
              </div>
              <div class="cell large-4 hide-for-medium">
              </div>
              <div class="cell">
                <div v-if="demand.advertisingToken && advertisingOrganization" class="md-title-textarea">
                  <i>Empfohlen von : <strong class="md-title">{{advertisingOrganization}}</strong></i>
                </div>
                <br>
              </div>
            </div>
            <div v-else class="grid-x grid-padding-x">
              <div class="cell large-8">
                <div class="md-title">
                  <span>Software für Soziale Einrichtungen (SoSE)&nbsp;&nbsp;&nbsp;<strong>Wichtige Mitteilung</strong></span>
                </div>
                <br>
                <div class="md-title-textarea text-justify">
                  Wir freuen uns über ihr Interesse an der <span>Software für Soziale Einrichtungen</span>.
                  <br><br>
                  Möchten Sie mit uns Kontakt aufnehmen, so senden Sie uns einfach eine Email an
                  <a :href= "contactEmailMessage" target="_blank"><b>contact@ai42.de</b></a> mit Ihrem Anliegen.
                  Wir freuen uns auf Ihre Nachricht.
                  <br><br>
                  Mit besten Wünschen ihr <span>ai42 Team</span>!
                </div>
              </div>
              <div class="cell large-4 hide-for-medium"></div>
            </div>
          </md-card-header>
          <md-card-content v-if="!demand.testAccountDemand || organization.testPhaseActive || organization.customerState === 'CONFIRMED' || showPriceCalculator">
            <div v-if="concludeContractActive || showPriceCalculator">
              <md-steppers v-if="!contractConcluded" :md-active-step.sync="activeStep" md-linear :md-dynamic-height="true">

                <md-step id="firstStep" :md-done.sync="firstStep"
                         md-label="Bundle konfigurieren" md-description="Module und Anzahl Kinder festlegen">
                  <div class="grid-x md-textarea">
                    <div class="cell large-6 medium-6"><span><strong>Bitte wählen Sie Ihre Module</strong></span><br><br></div>
                    <div class="cell large-6 medium-6 hide-for-small-only"><md-progress-bar md-mode="indeterminate" v-if="sending"/></div>
                  </div>
                  <div v-if="preFilledContract" class="cell text-center">
                    <span v-for="module of availableModules" :key="module.type" @click="selectModule(module)">
                      <md-card md-with-hover class="text-left">
                        <md-ripple :class="getSelectedClass(module)">
                          <md-card-header>
                            <md-card-media>
                              <img src="../assets/icons/modules.png" style="width: 30px">
                            </md-card-media>

                            <md-card-header-text>
                              <div class="md-title" style="font-size: 15px;">{{module.label}}</div>
                              <div v-if="module.type === 'BASE' || module.type === 'DOCUMENTATION_STANDALONE'">Grundmodul</div>
                              <div v-else>Zusatzmodul</div>
                            </md-card-header-text>
                          </md-card-header>

                          <md-card-content style="min-height: 160px; max-height: 160px;">
                            <hr style="margin-top: 0">
                            <md-content class="md-scrollbar">
                              <div class="md-subhead">{{module.description}}</div>
                            </md-content>
                          </md-card-content>

                          <md-card-actions :class="getSelectedClass(module)">
                            <div v-if="isSelected(module)" class="text-center">
                              <h5 style="color: seagreen; font-weight: 300;">ausgewählt&nbsp;<md-icon style="color: seagreen">done</md-icon></h5>
                            </div>
                            <div v-else>
                              <h4><md-icon style="color: black">add_shopping_cart</md-icon></h4>
                              <md-tooltip><b>{{module.label}}</b>&nbsp;hinzufügen</md-tooltip>
                            </div>
                          </md-card-actions>
                        </md-ripple>
                      </md-card>
                    </span>
                  </div>
                  <br><br>
                  <div v-if="preFilledContract && preFilledContract.viewValues.durchschnittMonatErstesJahrBrutto > 0 && labels" class="grid-x grid-padding-x" style="min-height: 500px;">
                    <div class="cell large-3 text-center">
                      <h4>Ausgewähltes Bundle</h4>
                      <img src="../assets/icons/purchase.png" style="width: 70px; margin-bottom: 10px;">

                      <div v-if="demand.numChildren && demand.numChildren > 0">
                        <h5><span><b>{{demand.numChildren}}</b></span> Kinder</h5>
                        <br>

                        <div v-if="hasTemporaryPercentageDiscount()">
                          <h5>
                            <span><b>{{(preFilledContract.viewValues.durchschnittMonatErstesJahrBrutto / 100) | currency}}</b></span> im Monat<br>
                            <span><b>{{(preFilledContract.viewValues.durchschnittMonatErstesJahrBruttoJeKind / 100) | currency}}</b></span> je Kind<br>
                          </h5>
                          <h4 style="font-size: 1.2rem;">durchschnittlich <strong>im 1. Jahr</strong></h4>
                          <br>
                        </div>
                        <h5>
                          <span><b>{{(preFilledContract.viewValues.durchschnittMonatFolgeJahrBrutto / 100) | currency}}</b></span> im Monat<br>
                          <span><b>{{(preFilledContract.viewValues.durchschnittMonatFolgeJahrBruttoJeKind / 100) | currency}}</b></span> je Kind<br>
                        </h5>
                        <h4 v-if="hasTemporaryPercentageDiscount()" style="font-size: 1.2rem;"><strong>nach</strong> Rabattende</h4>
                        <h4 style="font-size: 1rem;"><br>(alle Preise enthalten {{preFilledContract.viewValues.ersteZahlungUmStPercentage}}% USt.)</h4>
                      </div>

                      <br>
                    </div>
                    <div class="cell large-6">
                      <div class="grid-x md-textarea">
                        <div class="cell"><span><strong>Bitte wählen Sie Ihren individuellen Bedarf</strong></span><br><br></div>
                      </div>
                      <div class="grid-x grid-padding-x">
                        <div class="cell small-4 text-center">
                          <md-field>
                            <label>Anzahl Kinder
                              <md-tooltip>Maximale Anzahl Kinder, die mit SoSE verwaltet soll.<br>Der Preis wird entsprechend angepasst.</md-tooltip>
                            </label>
                            <md-input id="numChildren" type="number" @keyup="onSelectNumChildren()" @change="onSelectNumChildren()" v-model="demand.numChildren"></md-input>
                          </md-field>
                        </div>
                        <div class="cell small-4 text-center">
                          <md-field>
                            <label>Zahlungsintervall
                              <md-tooltip>Mögliche Zahlungsrabatte nutzen.<br>Wählen Sie eine passende Option.</md-tooltip>
                            </label>
                            <md-select v-model="demand.paymentInterval" @md-selected="onSelectPaymentInterval()">
                              <md-option v-for="discount of preFilledContract.paymentDiscounts"
                                         v-if="discount.type === 'PAYMENT_INTERVAL_DISCOUNT'"
                                         :value="discount.interval" :key="discount.interval">
                                {{getDiscountFullLabel(discount)}}
                              </md-option>
                            </md-select>
                          </md-field>
                        </div>
                        <div class="cell small-4 text-center">
                          <md-checkbox v-if="sokeDiscount" v-model="demand.partnerMember"
                                       @change="getPreFilledContract(demand)"
                                       class="md-primary" style="margin-top: 26px;">Soke e.V. Mitglied
                            <md-tooltip>{{sokeDiscount.label}}</md-tooltip>
                          </md-checkbox>
                        </div>
                      </div>
                      <div class="cell">
                        <span v-for="availableModule of availableModules" :key="availableModule.id">
                          <md-chip v-for="module of selectedModules" v-if="module === availableModule.type" :key="module.id"
                                   class="md-primary" md-clickable style="margin-right: 8px;">{{availableModule.label}}</md-chip>
                        </span>
                      </div>
                      <br>

                      <div v-if="hasTemporaryPercentageDiscount() && demand.numChildren && demand.numChildren > 0" class="cell text-center">
                        <br>
                        <h4 style="font-size: 1.5rem">Entwicklung der Kosten</h4>
                        <BarChart v-if="settlementBarChartData && settlementBarChartOptions"
                                  :chartData="settlementBarChartData" style="max-height: 200px"
                                  :options="settlementBarChartOptions"></BarChart>
                      </div>
                      <br>
                    </div>
                    <div class="cell large-3 text-center">
                      <h3>
                        <strong v-if="hasTemporaryPercentageDiscount()">Preis 1. Zahlung</strong>
                        <strong v-else>Preis</strong>
                      </h3>
                      <div v-if="demand.numChildren && demand.numChildren > 0">
                        <h5>({{labels.paymentInterval[demand.paymentInterval]}})</h5>
                        <h4><span><strong>{{(preFilledContract.viewValues.preisErsteZahlungBrutto / 100) | currency}}</strong></span></h4>
                        <h4 style="font-size: 1rem;">(enthaltene {{preFilledContract.viewValues.ersteZahlungUmStPercentage}}% USt. {{(preFilledContract.viewValues.ersteZahlungUmSt / 100) | currency}})</h4>
                        <div v-if="preFilledContract.viewValues.durchschnittMonatErstesJahrBrutto > 0">
                          <h5 v-for="(discount, index) of preFilledContract.paymentDiscounts" :key="index" v-if="discount.valid">
                            <div v-if="isPaymentIntervalDiscount(discount, demand)">
                              <b><span>{{discount.discountPercentage}}%</span></b> {{discount.shortLabel}}
                            </div>
                            <div v-if="isPercentageDiscount(discount)">
                              <b><span>{{discount.discountPercentage}}%</span></b> {{discount.shortLabel}}
                            </div>
                            <md-tooltip>{{discount.label}}</md-tooltip>
                          </h5>
                          <h5 v-if="sokeDiscount && demand.partnerMember"><b><span>{{sokeDiscount.discountPercentage}}%</span></b> Soke-Rabatt <md-tooltip>{{sokeDiscount.label}}</md-tooltip></h5>
                        </div>
                        <br><br><br>
                        <a href="https://ai42.de/unternehmen/agb" target="_blank">
                          <button class="button secondary">&nbsp;<b>§</b>&nbsp;Beachten Sie auch unsere AGB&nbsp;</button>
                          <md-tooltip>Allgemeine Geschäftsbedingungen</md-tooltip>
                        </a>
                        <br><br>
                      </div>
                    </div>

                    <div class="cell text-center md-textarea"><span><strong>Alle Angaben können nach Vertragsabschluss bei Bedarf jederzeit im Bereich Verwaltung angepasst werden.</strong></span><br><br></div>
                  </div>
                  <div v-if="preFilledContract && preFilledContract.viewValues.durchschnittMonatErstesJahrBrutto <= 0" class="cell text-center">
                    <h4>Ihre Auswahl beinhaltet <b style="color: seagreen;">kostenfreie</b> Leistungen.</h4>
                  </div>
                  <br><br>
                  <div v-if="!showPriceCalculator" class="cell text-right">
                    <button class="button success" :disabled="!(demand.numChildren && demand.numChildren > 0)"
                            @click="setDone('firstStep', 'secondStep')">Einrichtung registrieren &#9654;</button>
                  </div>
                  <div v-else class="cell text-center">
                    <br>
                    <h5>Ab September 2020 können Sie hier Ihren SoSE-Vertrag abschließen.</h5>
                  </div>
                </md-step>
                <md-step v-if="!showPriceCalculator" id="secondStep" :md-done.sync="secondStep" md-label="Einrichtung registrieren" :md-error="secondStepError"
                         md-description="Erfassen notwendiger Stammdaten">
                  <div class="md-textarea">
                    <span><strong>Registrieren Sie Ihre Einrichtung</strong></span>
                    <br><br>
                  </div>

                  <div class="grid-x">
                    <div class="cell large-8">

                      <h5>Einrichtung</h5>
                      <RegistrationForm ref="registrationForm" :registration="demand" :passwordForm="passwordForm" :sending="sending"></RegistrationForm>

                      <h5>Adresse</h5>
                      <AddressForm ref="addressForm" :address="demand.organizationAddress" :sending="sending"></AddressForm>

                      <div v-if="preFilledContract && preFilledContract.viewValues.durchschnittMonatErstesJahrBrutto > 0">
                        <br>
                        <h5>Zahlungsweise</h5>
                        <span>
                          <md-radio v-model="demand.useSepaForPayment" :value="true" class="md-primary" :disabled="!sepaPaymentActive">SEPA Lastschrift</md-radio>
                          <md-tooltip>
                            <span style="color: white" v-if="!sepaPaymentActive">Das SEPA-Lastschrift Verfahren steht in Kürze zur Verfügung und kann dann im Verwaltungsbereich aktiviert werden.<br>Bis dahin bezahlen Sie bitte auf Rechnung.
                            </span>
                          </md-tooltip>
                        </span>
                        <md-radio v-model="demand.useSepaForPayment" :value="false" class="md-primary">Rechnung</md-radio>

                        <div v-if="demand.useSepaForPayment">
                          <br>
                          <h5>Bankverbindung</h5>
                          <BankAccountForm ref="bankAccountForm" :bankAccount="demand.account" :mandateReferenceDisabled="true"
                                           paymentMethod="SEPA" :sending="sending"></BankAccountForm>
                        </div>
                      </div>
                    </div>
                    <div class="cell large-4">
                    </div>
                  </div>

                  <br><br>
                  <div class="grid-x">
                    <div class="cell small-6 text-left">
                      <button class="button success" @click="resetDone('secondStep', 'firstStep')">&#9664; Zurück zum Bundle</button>
                    </div>
                    <div class="cell small-6 text-right">
                      <button class="button success" @click="setDone('secondStep', 'thirdStep')">Eingaben bestätigen &#9654;</button>
                    </div>
                  </div>
                </md-step>
                <md-step v-if="!showPriceCalculator" id="thirdStep" :md-done.sync="thirdStep" md-label="Eingaben bestätigen"
                         md-description="Vertrag abschließen">
                  <div v-if="preFilledContract" class="md-textarea">
                    <span><strong>Bestätigen Sie Ihre Eingaben</strong></span>
                    <br><br>

                    <div class="grid-x grid-padding-x">
                      <div class="cell large-6 medium-12 small-12">
                        <md-card style="min-height: 540px; width: 100%;">
                          <md-card-header>
                            <span class="md-title"><h4>Ihre Eingaben</h4></span>
                          </md-card-header>

                          <md-card-content class="md-result-textarea">
                            <h5>Einrichtung</h5>
                            <table style="width:100%">
                              <tr>
                                <td style="width: 40%">Organisation / Träger</td>
                                <td style="width: 60%"><b>{{demand.organizationName}}</b></td>
                              </tr>
                              <tr>
                                <td>Organisationsform</td>
                                <td><b>{{labels.organizationType[demand.organizationType]}}</b></td>
                              </tr>
                              <tr>
                                <td>Email</td>
                                <td><b>{{demand.email}}</b></td>
                              </tr>
                              <tr v-if="!organization.testPhaseActive">
                                <td>Passwort</td>
                                <td v-if="demand.password && demand.password === demand.passwordRepeat"><b><md-icon style="color: green">check</md-icon></b></td>
                                <td v-else><b><md-icon style="color: red">close</md-icon></b>
                                  <md-tooltip>Passwort entspricht nicht den Vorgaben</md-tooltip>
                                </td>
                              </tr>
                            </table>
                            <div v-if="preFilledContract && preFilledContract.viewValues.durchschnittMonatErstesJahrBrutto > 0">
                              <hr>
                              <h5>Zahlungsweise</h5>
                              <table style="width:100%">
                                <tr>
                                  <td style="width: 40%">Zahlungsweise</td>
                                  <td v-if="demand.useSepaForPayment" style="width: 60%"><b>SEPA Lastschrift</b></td>
                                  <td v-else style="width: 60%"><b>Rechnung</b></td>
                                </tr>
                              </table>
                              <div v-if="demand.useSepaForPayment">
                                <hr>
                                <h5>Bankverbindung</h5>
                                <table style="width:100%">
                                  <tr>
                                    <td style="width: 40%">Kontoinhaber</td>
                                    <td style="width: 60%"><b>{{demand.account.accountOwner}}</b></td>
                                  </tr>
                                  <tr>
                                    <td>Name der Bank</td>
                                    <td><b>{{demand.account.bankname}}</b></td>
                                  </tr>
                                  <tr>
                                    <td>IBAN</td>
                                    <td><b>{{demand.account.iban}}</b></td>
                                  </tr>
                                  <tr>
                                    <td>BIC</td>
                                    <td><b>{{demand.account.bic}}</b></td>
                                  </tr>
                                </table>
                              </div>
                            </div>
                          </md-card-content>
                        </md-card>
                      </div>
                      <div class="cell large-6 medium-12 small-12">
                        <md-card style="min-height: 540px; width: 100%;">
                          <md-card-header style="padding-bottom: 0">
                            <span class="md-title">
                              <h4>Ausgewähltes Bundle&nbsp;&nbsp;&nbsp;<img src="../assets/icons/purchase.png" style="width: 40px; margin-bottom: 10px;"></h4>
                            </span>
                          </md-card-header>

                          <md-card-content class="md-result-textarea">
                            <h5>Module</h5>
                            <br>
                            <span v-for="availableModule of availableModules" :key="availableModule.id">
                              <md-chip v-for="module of selectedModules" v-if="module === availableModule.type" :key="module.id"
                                       class="md-primary" md-clickable style="margin-right: 8px;">{{availableModule.label}}</md-chip>
                            </span>
                            <div v-if="preFilledContract && preFilledContract.viewValues.durchschnittMonatErstesJahrBrutto > 0">
                              <hr>
                              <h5>Konfiguration</h5>
                              <table style="width:100%">
                                <tr>
                                  <td style="width: 40%">Anzahl Kinder</td>
                                  <td style="width: 60%"><b>{{demand.numChildren}}</b></td>
                                </tr>
                                <tr>
                                  <td>Zahlungsintervall</td>
                                  <td><b>{{labels.paymentInterval[demand.paymentInterval]}}</b></td>
                                </tr>
                                <tr v-for="(discount, index) of preFilledContract.paymentDiscounts" :key="index" v-if="discount.valid">
                                  <td v-if="isPaymentIntervalDiscount(discount, demand)">{{discount.shortLabel}}</td>
                                  <td v-if="isPaymentIntervalDiscount(discount, demand)"><b>{{discount.discountPercentage}}%</b></td>
                                  <td v-if="isPercentageDiscount(discount)">{{discount.shortLabel}}</td>
                                  <td v-if="isPercentageDiscount(discount)"><b>{{discount.discountPercentage}}%</b></td>
                                  <md-tooltip>{{discount.label}}</md-tooltip>
                                </tr>
                                <tr v-if="demand.partnerMember">
                                  <td>Soke-Rabatt</td>
                                  <td v-if="sokeDiscount && demand.partnerMember"><b>{{sokeDiscount.discountPercentage}}%</b></td>
                                  <td v-else><b>keinen</b></td>
                                </tr>
                              </table>
                              <hr>
                              <h5>Kosten</h5>
                              <table style="width:100%">
                                <tr v-if="hasTemporaryPercentageDiscount()">
                                  <td style="width: 40%">Preis 1. Zahlung {{labels.paymentInterval[demand.paymentInterval]}}</td>
                                  <td style="width: 60%"><b>{{(preFilledContract.viewValues.preisErsteZahlungBrutto / 100) | currency}}</b></td>
                                </tr>
                                <tr v-if="demand.paymentInterval !== 'MONTHLY'">
                                  <td style="width: 40%">entspricht monatlich</td>
                                  <td style="width: 60%"><b>{{(preFilledContract.viewValues.durchschnittMonatErstesJahrBrutto / 100) | currency}}</b></td>
                                </tr>
                                <tr>
                                  <td style="width: 40%">je Kind monatlich</td>
                                  <td style="width: 60%"><b>{{(preFilledContract.viewValues.durchschnittMonatErstesJahrBruttoJeKind / 100) | currency}}</b></td>
                                </tr>
                                <tr>
                                  <td v-if="hasTemporaryPercentageDiscount()" style="width: 40%">nach 1. Jahr {{labels.paymentInterval[demand.paymentInterval]}}</td>
                                  <td v-else style="width: 40%">{{labels.paymentInterval[demand.paymentInterval]}}</td>
                                  <td style="width: 60%"><b>{{(preFilledContract.viewValues.gesamtKostenBruttoFolgeIntervall / 100) | currency}}</b></td>
                                </tr>
                              </table>
                              <h4 style="font-size: 1rem;"><br>(alle Preise enthalten <b>{{preFilledContract.viewValues.ersteZahlungUmStPercentage}}%</b> USt.)</h4>
                            </div>
                            <div v-if="preFilledContract && preFilledContract.viewValues.durchschnittMonatErstesJahrBrutto <= 0" class="cell text-left">
                              <br>
                              <h4>Ihre Auswahl beinhaltet <b style="color: seagreen;">kostenfreie</b> Leistungen.</h4>
                            </div>
                          </md-card-content>
                        </md-card>
                      </div>
                    </div>
                  </div>
                  <br><br>
                  <div v-if="preFilledContract" class="grid-x grid-padding-x">
                    <div class="cell large-2 hide-for-medium-only hide-for-small-only"></div>
                    <div class="cell large-8 medium-12 small-12">
                      <div v-if="demand.organizationName.length > 0" class="text-justify md-result-textarea">
                        Mit dem nächsten Schritt wird ein <b>Software-As-A-Service</b> (SAAS) <b>Vertrag</b> zwischen dem Träger
                        <b>{{demand.organizationName}}</b> und der <b>ai42 UG (haftungsbeschränkt)</b> abgeschlossen.
                        Der Service beinhaltet die oben aufgeführten Leistungsmerkmale zu der regelmäßig
                        vereinbarten Gebühr und wird gemäß unseren AGB durchgeführt.
                      </div>
                      <br>
                      <md-checkbox class="md-primary" v-model="demand.agbConfirmed">
                      </md-checkbox>
                      <span style="font-size: 1rem; position: relative;top: -4px;left: 8px;">Ich habe die <a href="https://ai42.de/unternehmen/agb" target="_blank"><b>AGB</b></a> gelesen und bin damit einverstanden.
                        <md-tooltip>Bitte lesen Sie unsere allgemeinen Geschäftsbedingungen (AGB) sorgfältig durch und stimmen Sie diesen zu.</md-tooltip>
                      </span>
                      <br>
                      <md-checkbox class="md-primary" v-model="demand.dsgvoConfirmed">
                      </md-checkbox>
                      <span style="font-size: 1rem; position: relative;top: -4px;left: 8px;">Die <a href="https://ai42.de/unternehmen/datenschutz" target="_blank"><b>Datenschutzerklärung</b></a> habe ich zur Kenntnis genommen.
                        <md-tooltip>Bitte lesen Sie unsere Datenschutzerklärung sorgfältig durch und bestätigen Sie die Kenntisnahme.</md-tooltip>
                      </span>
                    </div>
                    <div class="cell large-2 hide-for-medium-only hide-for-small-only"></div>
                  </div>
                  <br>
                  <div v-if="!organization.testPhaseActive" class="grid-x">
                    <div class="cell large-2"></div>
                    <div class="cell large-8 text-center">
                      <vue-recaptcha
                          ref="recaptcha"
                          :sitekey="siteKey"
                          @verify="onVerify"
                          @expired="onExpired">
                      </vue-recaptcha>
                    </div>
                    <div class="cell large-2"></div>
                  </div>
                  <br><br>
                  <div class="grid-x">
                    <div class="cell large-3 medium-4 small-6 text-left">
                      <button class="button success" @click="resetDone('thirdStep', 'secondStep')" :disabled="sending">&#9664; Daten korrigieren</button>
                    </div>
                    <div class="cell large-6 hide-for-medium-only hide-for-small-only text-center">
                      <button class="button warn" :disabled="!demand.agbConfirmed || !demand.dsgvoConfirmed || recaptchaResponse.length === 0 || sending"
                              @click="setDone('thirdStep', '')">
                        Vertrag jetzt kostenpflichtig abschießen
                      </button>
                      <md-tooltip><p v-if="!demand.agbConfirmed || !demand.dsgvoConfirmed || recaptchaResponse.length === 0">Bitte stimmen Sie den AGB zu und nehmen Sie die Datenschutzerklärung zur Kenntnis.<span v-if="!organization.testPhaseActive" style="color: white;"> Bestätigen Sie bitte auch das CAPTCHA.</span></p></md-tooltip>
                    </div>
                    <div class="cell large-3 medium-4 hide-for-small-only">
                      <div v-if="sending" class="md-result-textarea grid-x">
                        <div class="cell large-2 small-2 medium-12 text-center">
                          <vue-simple-spinner></vue-simple-spinner>
                        </div>
                        <div class="cell large-10 small-10 hide-for-medium-only text-left" style="padding-top: 5px;">
                          &nbsp;Daten übertragen ...
                        </div>
                      </div>
                    </div>
                    <div class="cell medium-4 small-6 hide-for-large text-right">
                      <button class="button warn" :disabled="!demand.agbConfirmed || !demand.dsgvoConfirmed || recaptchaResponse.length === 0 || sending"
                              @click="setDone('thirdStep', '')">Vertrag abschießen
                        <md-tooltip>Vertrag jetzt kostenpflichtig abschießen</md-tooltip>
                      </button>
                      <md-tooltip><p v-if="!demand.agbConfirmed || !demand.dsgvoConfirmed || recaptchaResponse.length === 0">Bitte stimmen Sie den AGB zu und nehmen Sie die Datenschutzerklärung zur Kenntnis. Bestätigen Sie bitte auch das CAPTCHA.</p></md-tooltip>
                    </div>
                  </div>
                </md-step>

              </md-steppers>
              <div v-else class="grid-x">
                <div class="cell large-3 medium-1 hide-for-small-only"></div>
                <div class="cell large-6 medium-10 small-12">
                  <br><br>
                  <div class="md-title text-center">
                    Herzlichen Dank für Ihren Auftrag <span style="color: red">&#9825;</span>
                  </div>
                  <hr>
                  <div v-if="newOrganizationActivated" class="md-title-textarea text-justify">
                    Der Vertrag wurde erfolgreich geschlossen.
                    Sie können ihre gebuchten <strong>SoSE Module</strong> ab sofort nutzen.
                    <hr>
                    Ihre Vertragsdaten können unter <router-link to="/Verwaltung/Traeger/Vertrag">Verwaltung / Träger</router-link> eingesehen werden.
                  </div>
                  <div v-else class="md-title-textarea text-justify">
                    Der Vertrag wurde erfolgreich geschlossen.
                    Sie erhalten in Kürze eine Email von uns.
                    Diese enthält einen Link zum Aktivieren Ihres SoSE-Zugangs.
                    Bitte überprüfen Sie auch Ihren Spam-Ordner.
                    <hr>
                    Nach Aktivierung können Sie sich mit Ihrer Email <strong>{{demand.email}}</strong>
                    und Ihrem angegebenen Passwort sofort <router-link to="/Login">hier anmelden</router-link>.
                  </div>
                  <br><br>
                </div>
                <div class="cell large-3 medium-1 hide-for-small-only"></div>
              </div>
            </div>
          </md-card-content>
          <md-card-content v-else>
            <div v-if="testLicenseActive && !contractConcluded">
              <div v-if="!testAccountRequested" class="grid-x">
                <div class="cell large-2 medium-1 hide-for-small-only"></div>
                <div class="cell large-8 medium-10 small-12">
                  <div class="md-title">
                    <span><u>Informationen zum Test</u></span>
                  </div>
                  <br>
                  <div v-if="friendsAndFamiliyActive" class="md-title-textarea text-justify">
                    Sie können <b><span>SoSE</span></b> für einen Zeitraum von <strong>einem Monat kostenfrei</strong> uneingeschränkt für Ihre Einrichtung testen.
                    Möchten Sie im Anschluß <b><span>SoSE</span></b> nutzen, so können Sie nahtlos mit den bereits eingegebenen Daten fortfahren.
                    Wählen Sie hierfür unten die <strong>Option</strong>&nbsp;<i><span>Datenspeicherung nach Testzeit</span></i>.
                    Anderenfalls wird der eingerichtete Test-Zugang mit allen angelegten Daten nach Ablauf der <span>Friends & Family</span> Test-Phase vollständig gelöscht.
                    <br><br><br>
                    Für die Teilnahme an <span>Friends & Family</span> Test-Phase benötigen wir nur folgende Informationen:
                  </div>
                  <div v-else class="md-title-textarea text-justify">
                    Sie können <b><span>SoSE</span></b> für einen Zeitraum von <strong>einem Monat kostenfrei</strong> uneingeschränkt für Ihre Einrichtung testen.
                    Möchten Sie im Anschluß <b><span>SoSE</span></b> nutzen, so können Sie nahtlos mit den bereits eingegebenen Daten fortfahren.
                    Wählen Sie hierfür unten die <strong>Option</strong>&nbsp;<i><span>Datenspeicherung nach Testzeit</span></i>.
                    Anderenfalls wird der eingerichtete Test-Zugang mit allen angelegten Daten nach Ablauf des Testzeitraums vollständig gelöscht.
                    <br><br><br>
                    Für die Bereitstellung Ihrer <strong>Test-Lizenz</strong> benötigen wir nur folgende Informationen:
                  </div>
                  <hr>
                  <div class="md-title-textarea text-justify">
                    <h5>Einrichtung</h5>
                    <RegistrationForm ref="registrationForm" :registration="demand" :passwordForm="passwordForm" :sending="sending"></RegistrationForm>
                    <br><br>
                    Für die Testzeit möchten wir Sie um folgende Einwilligungen bitten:
                    <hr>
                    <div class="grid-x grid-padding-x">
                      <div class="cell large-6 medium-6 small-12">
                        <md-checkbox class="md-primary" v-model="demand.testAccountData.allowDataStorageDuringTestphase">
                          <span style="font-size: 1rem">Datenspeicherung während Testzeit</span>
                          <md-tooltip>Diese Zustimmung ist für den Test <b>zwingend</b> notwendig.</md-tooltip>
                        </md-checkbox>
                        <span :class="getValidationClass('allowDataStorageDuringTestphase')">
                        <div class="md-error-checkbox"
                             v-if="$v.demand.testAccountData.allowDataStorageDuringTestphase.$invalid && $v.demand.testAccountData.allowDataStorageDuringTestphase.$dirty">Diese Zustimmung ist notwendig</div>
                      </span>
                      </div>
                      <div class="cell large-6 medium-6 small-12">
                        <md-checkbox class="md-primary" v-model="demand.testAccountData.allowContactDuringTestphase">
                          <span style="font-size: 1rem">Kontaktaufnahme während Testzeit</span>
                          <md-tooltip>Diese Zustimmung <b>würde uns freuen</b>, ist für den Test aber nicht notwendig.</md-tooltip>
                        </md-checkbox>
                      </div>
                      <div class="cell large-6 medium-6 small-12">
                        <md-checkbox class="md-primary" v-model="demand.testAccountData.allowDataStorageEnhancedDuration">
                          <span style="font-size: 1rem">Datenspeicherung nach Testzeit</span>
                          <md-tooltip>Diese Zustimmung <b>hilft Ihnen</b> nach dem Test-Zeitraum nahtlos mit SoSE zu starten.</md-tooltip>
                        </md-checkbox>
                      </div>
                      <div class="cell large-6 medium-6 small-12">
                        <md-checkbox class="md-primary" v-model="demand.testAccountData.allowContactEnhancedDuration">
                          <span style="font-size: 1rem">Kontaktaufnahme nach Testzeit</span>
                          <md-tooltip>Diese Zustimmung <b>würde uns sehr freuen</b>, ist für den Test aber nicht notwendig.</md-tooltip>
                        </md-checkbox>
                      </div>
                    </div>
                    <br><br>
                    Das Wichtigste noch zum Schluß
                    <hr>

                    <md-checkbox class="md-primary" v-model="demand.agbConfirmed">
                    </md-checkbox>
                    <span style="font-size: 1rem; position: relative;top: -4px;left: 8px;">Ich habe die <a href="https://ai42.de/unternehmen/agb" target="_blank"><b>AGB</b></a> gelesen und bin damit einverstanden.
                        <md-tooltip>Bitte lesen Sie unsere allgemeinen Geschäftsbedingungen (AGB) sorgfältig durch und stimmen Sie diesen zu.</md-tooltip>
                      </span>
                    <br>

                    <md-checkbox class="md-primary" v-model="demand.dsgvoConfirmed">
                    </md-checkbox>
                    <span style="font-size: 1rem; position: relative;top: -4px;left: 8px;">Die <a href="https://ai42.de/unternehmen/datenschutz" target="_blank"><b>Datenschutzerklärung</b></a> habe ich zur Kenntnis genommen.
                      <md-tooltip>Bitte lesen Sie unsere Datenschutzerklärung sorgfältig durch und bestätigen Sie die Kenntisnahme.</md-tooltip>
                    </span>
                    <br><br>
                    <vue-recaptcha
                        ref="recaptcha"
                        :sitekey="siteKey"
                        @verify="onVerify"
                        @expired="onExpired">
                    </vue-recaptcha>
                  <br>
                    <div class="grid-x">
                      <div class="cell large-4 medium-2 small-2"></div>
                      <div class="cell large-4 medium-8 small-8 text-center">
                        <button class="button warn" :disabled="!demand.agbConfirmed || !demand.dsgvoConfirmed || recaptchaResponse.length === 0 || sending"
                                @click="onRequestTestLicense()">
                          Test-Lizenz jetzt anfordern
                        </button>
                        <md-tooltip>
                          <p v-if="!demand.agbConfirmed || !demand.dsgvoConfirmed || recaptchaResponse.length === 0">Bitte <span v-if="!friendsAndFamiliyActive" style="color: white">stimmen Sie den AGB zu und </span>nehmen Sie die Datenschutzerklärung zur Kenntnis. Bestätigen Sie bitte auch das CAPTCHA.</p>
                          <p v-else-if="!demand.testAccountData.allowDataStorageDuringTestphase">Bitte stimmen Sie auch der <b>Datenspeicherung während der Testzeit</b> zu.</p>
                        </md-tooltip>
                      </div>
                      <div class="cell large-4 medium-2 small-2 text-center">
                        <div v-if="sending" class="md-result-textarea grid-x">
                          <div class="cell large-2 text-center">
                            <vue-simple-spinner></vue-simple-spinner>
                          </div>
                          <div class="cell large-10 hide-for-medium-only hide-for-small-only text-left" style="padding-top: 5px;">
                            &nbsp;Daten übertragen ...
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="cell large-2 medium-1 hide-for-small-only"></div>
              </div>
              <div v-else class="grid-x">
                <div class="cell large-3 medium-1 hide-for-small-only"></div>
                <div class="cell large-6 medium-10 small-12">
                  <br>
                  <div class="md-title text-center">
                    Geschafft! <span style="color: seagreen">&#9786;</span> Herzlichen Dank für Ihr Interesse an <b><span>SoSE</span></b>
                  </div>
                  <hr>
                  <div class="md-title-textarea text-justify">
                    Ihre Test-Lizenz wurde erstellt.
                    Sie erhalten in Kürze eine Email von uns.
                    Diese enthält einen Link zum Aktivieren Ihres SoSE-Test-Zugangs.
                    Bitte überprüfen Sie auch Ihren Spam-Ordner.
                    <hr>
                    Nach Aktivierung können Sie sich mit Ihrer Email <strong>{{demand.email}}</strong>
                    und Ihrem angegebenen Passwort sofort <router-link to="/Login">hier anmelden</router-link>.
                  </div>
                  <br><br>
                </div>
                <div class="cell large-3 medium-1 hide-for-small-only"></div>
              </div>
            </div>
          </md-card-content>
        </md-card>
      </div>
    </div>

    <md-dialog :md-click-outside-to-close="false" :md-active.sync="error">
      <md-dialog-title style="color: red">Es ist ein Fehler aufgetreten</md-dialog-title>

      <md-dialog-content class="md-result-textarea text-justify">
        Beim Verarbeiten Ihrer Anfrage ist ein Fehler aufgetreten.
        Bitte überprüfen Sie Ihre Angaben, korrigieren Sie diese gegebenenfalls und versuchen es erneut.<br><br>
        Wenn Sie schon einen Testzugang haben, so ist Ihre Einrichtung bereits registriert. Bitte melden Sie sich in diesem Fall erst mit diesem Zugang an, um anschließend ihr Bundle zu konfigurieren und zu bestellen.<br><br>
        Alternativ können Sie uns unter der <a :href= "registerEmailMessage" target="_blank">Email-Adresse <b>contact@ai42.de</b> kontaktieren</a>.
      </md-dialog-content>

      <div class="text-center">
        <button class="button md-primary" @click="resetDone('thirdStep', 'secondStep')">Verstanden</button>
      </div>
    </md-dialog>
  </div>
</template>

<script>
  import Spinner from 'vue-simple-spinner';
  import VueRecaptcha from 'vue-recaptcha';
  import Vue from 'vue';
  import InfoService from '../services/InfoService';
  import HttpErrorHandler from '../services/HttpErrorHandler';
  import BankAccountForm from '../components/forms/BankAccountForm';
  import RegistrationForm from '../components/forms/RegistrationForm';
  import TestAccountData from '../entities/TestAccountData';
  import Address from '../entities/Address';
  import BarChart from '../components/charts/BarChart';
  import {validationMixin} from 'vuelidate';
  import {
    sameAs,
  } from 'vuelidate/lib/validators';
  import AddressForm from "../components/forms/AddressForm";

  let base64 = require('base-64');

  export default {
    name: 'Registration',
    mixins: [validationMixin],

    components: {
      AddressForm,
      'vue-simple-spinner': Spinner,
      VueRecaptcha,
      BankAccountForm: BankAccountForm,
      RegistrationForm: RegistrationForm,
      BarChart,
    },

    created() {
      this.$router.beforeEach((to, from, next) => {
        this.showPriceCalculator = to.path.toLowerCase().includes('preise');
        next();
      })
    },

    mounted() {
      this.setModesFromRouterPath();
      this.onToggleTestLicense();
      this.reloadSettings();
    },

    data() {
      return {
        contactEmailMessage: 'mailto:contact@ai42.de?subject=SoSE-Kontaktanfrage&body=Sehr%20geehrte%20Damen%20und%20Herren,%20wir%20möchten%20uns%20gerne%20mit%20Ihnen%20austauschen.%20Bitte%20kontaktieren%20Sie%20uns%20hierfür%20per%20email.%20Danke%20und%20Grüße',
        registerEmailMessage: 'mailto:contact@ai42.de?subject=SoSE-Registrierungsanfrage&body=Sehr%20geehrte%20Damen%20und%20Herren,%20wir%20möchten%20uns%20gerne%20für%20SoSE%20registrieren.%20Bitte%20kontaktieren%20Sie%20uns%20hierfür%20per%20email.%20Danke%20und%20Grüße',
        sending: false,
        success: false,
        error: false,
        preFilledContract: null,
        temporaryPercentageDiscount: null,
        newOrganization: null,
        newOrganizationActivated: false,
        demand: {
          account: {
            accountOwner: '',
            bankname: '',
            bic: '',
            dateOfMandateGranting: Vue.moment(),
            iban: '',
            mandateReference: '',
            numberOfSuccessfulSEPATransactions: 0,
            valid: true,
            mandateType: 'ONE_OFF'

            /*          iban: 'DE02500105170137075030',
                        bic: 'INGDDEFF',
                        bankname: 'ING-DIBA',
                        mandateReference: 'X8A343IK',
                        dateOfMandateGranting: Vue.moment(),
                        accountOwner: 'Hans Meier',
                        numberOfSuccessfulSEPATransactions: 0,
                        valid: true,
                        mandateType: 'ONE_OFF'*/
          },

          advertisingToken: null,
          agbConfirmed: false,
          ai42Contract: null,
          contractConfirmed: true,
          dsgvoConfirmed: false,
          email: '',
          // email: 'info@regenbogen.de',
          keepDataOfTestphase: false,
          modules: [
            'BASE', 'EMPLOYEE', 'FINANCE', 'DATA_SECURITY'
          ],
          numChildren: '5',
          organizationAddress: Address.address,
          organizationName: '',
          organizationType: '',
          // organizationType: 'EV',
          // organizationName: 'Regenbogen',
          partnerMember: false,
          password: '',
          passwordRepeat: '',
          // password: '123456!abcdef',
          // passwordRepeat: '123456!abcdef',
          paymentInterval: 'MONTHLY',
          testAccountDemand: false,
          testAccountData: TestAccountData.testAccountData,
          useSepaForPayment: false,
        },

        advertisingOrganization: null,

        availableModules: [],
        selectedModules: ['BASE'],
        sokeDiscount: null,

        activeStep: 'firstStep',
        firstStep: false,
        secondStep: false,
        thirdStep: false,
        secondStepError: null,
        secondStepInvalid: true,

        siteKey: process.env.VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY,
        recaptchaResponse: '',
        contractConcluded: false,

        isInvalidAdvertisementToken: false,
        testLicenseExists: false,
        testAccountRequested: false,

        concludeContractActive: true,
        sepaPaymentActive: true,
        testLicenseActive: true,
        friendsAndFamiliyActive: false,
        passwordForm: true,

        showPriceCalculator: false,
        maxNumChildren: 1000,

        settlementBarChartData: null,
        settlementBarChartOptions: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [{
              stacked: true
            }],
            yAxes: [{
              stacked: true
            }]
          },
          legend: {
            display: true,
            position: 'bottom',
          },
          layout: {
            padding: {
              bottom: 10,
            }
          },
        },
      }
    },

    validations: {
      demand: {
        testAccountData: {
          allowDataStorageDuringTestphase: {
            sameAs: sameAs(() => true),
          },
        }
      },
    },

    methods: {

      reloadSettings() {
        if (this.webAppConfig.soseRegistrationDisabled) {
          return;
        }

        let maxTries = process.env.VUE_APP_RELOAD_MAX_TRIES;
        const reloadIntervalId = setInterval(() => {
          if (this.dataAvailable) {

            clearInterval(reloadIntervalId);

            this.passwordForm = !(this.organization && this.organization.testPhaseActive);
            if (this.organization && this.organization.testPhaseActive) {
              this.recaptchaResponse = 'some-value';
            }

            if (!this.isSage) {
              if (!this.isAuthenticated) {
                this.getLabelsLight();
              }
              setTimeout(() => {
                this.prefillDemand();
                this.getPreFilledContract(this.demand);
              }, 500);
            }

          } else {
            this.sending = true;
            maxTries--;
            if (maxTries <= 0) {
              this.sending = false;
              clearInterval(reloadIntervalId);
              HttpErrorHandler.handleError(null, this, 'Fehler beim Laden von Organisation / Labels');
            }
          }
        }, 250);
      },

      hasTemporaryPercentageDiscount() {
        if (this.temporaryPercentageDiscount) {
          return this.temporaryPercentageDiscount;
        }
        if (this.preFilledContract && this.preFilledContract.paymentDiscounts) {
          for (let discount of this.preFilledContract.paymentDiscounts) {
            if (discount.type === 'PERCENTAGE_DISCOUNT' && discount.validFrom && discount.validUntil) {
              this.temporaryPercentageDiscount = true;
              return this.temporaryPercentageDiscount;
            }
          }
        }

        this.temporaryPercentageDiscount = false;
        return this.temporaryPercentageDiscount;
      },

      isPaymentIntervalDiscount(discount, demand) {
        return discount.type === 'PAYMENT_INTERVAL_DISCOUNT' && discount.discountPercentage > 0 && discount.interval === demand.paymentInterval;
      },

      isPercentageDiscount(discount) {
        return discount.type === 'PERCENTAGE_DISCOUNT' && discount.discountPercentage > 0;
      },

      setModesFromRouterPath() {
        this.concludeContractActive = (process.env.VUE_APP_REGISTRATION_CONCLUDE_CONTRACT_ACTIVE === 'true');
        this.sepaPaymentActive = (process.env.VUE_APP_REGISTRATION_SEPA_PAYMENT_ACTIVE === 'true');
        this.testLicenseActive = (process.env.VUE_APP_REGISTRATION_TEST_LICENSE_ACTIVE === 'true');
        this.friendsAndFamiliyActive = (process.env.VUE_APP_FRIENDS_AND_FAMILY_ACTIVE === 'true');

        this.demand.testAccountDemand = this.$router.currentRoute.fullPath.toLowerCase().includes('test');
        // this.showPriceCalculator = this.$router.currentRoute.fullPath.toLowerCase().includes('preise');

        if (this.friendsAndFamiliyActive) {
          this.testLicenseActive = true;
          this.demand.testAccountDemand = true;
        }
      },

      onToggleTestLicense() {
        if (!this.concludeContractActive) {
          this.demand.testAccountDemand = true;
        }
      },

      prefillDemand() {
        if (this.organization && this.organization.testPhaseActive) {
          this.demand.organizationName = this.organization.name;
          this.demand.organizationType = this.organization.organizationType;
          this.demand.email = this.organization.contact.email;
        }
      },

      getValidationClass(fieldName) {
        let field = this.$v.demand.testAccountData[fieldName];

        if (field) {
          if (fieldName === 'isInvalidAdvertisementToken') {
            return {
              'md-invalid': this.isInvalidAdvertisementToken
            }
          }

          return {
            'md-invalid': field.$invalid && field.$dirty
          }
        }
      },

      onVerify: function (response) {
        this.recaptchaResponse = response;
        localStorage.setItem('x-recaptcha', response);
      },

      onExpired: function () {
        this.resetCaptcha();
      },

      resetCaptcha() {
        this.recaptchaResponse = '';
        localStorage.removeItem('x-recaptcha');
      },

      buildSettlementChart() {
        // Chartjs
        let datasets = [];
        let labels = [];
        let data = [];

        for (let item of this.preFilledContract.settlements) {
          data.push(item.dueAmountVAT / 100);
          labels.push(Vue.moment(item.dueDate).format('DD.MM.YYYY'));
        }

        datasets.push({
          label: 'Preis '  + this.labels.paymentInterval[this.demand.paymentInterval] + ' in €',
          backgroundColor: '#42ea50',
          data: data,
        });

        this.settlementBarChartData = {
          labels: labels,
          datasets: datasets,
        };
      },

      getLabelsLight() {
        InfoService.getLabelsLight()
          .then(response => {
            this.$store.commit('labelsLightInfo', response.data);
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Laden der Labels');
          });
      },

      getPreFilledContract(demand) {
        this.sending = true;
        if (this.demand.numChildren < 1) {
          this.demand.numChildren = 5;
        }

        const reducedDemand = {
          modules: demand.modules,
          numChildren: demand.numChildren,
          partnerMember: demand.partnerMember,
          paymentInterval: demand.paymentInterval,
          organizationAddress: demand.organizationAddress,
        }

        InfoService.getPreFilledContract(reducedDemand, this.organization && this.organization.testPhaseActive)
          .then(response => {
            this.preFilledContract = response.data.ai42Contract;
            this.demand.organizationAddress = response.data.organizationAddress;

            // This statement runs only one time after the initial loading
            if (this.availableModules.length <= 0 && this.preFilledContract) {
              this.availableModules = this.preFilledContract.modules;
              for (let discount of this.preFilledContract.paymentDiscounts) {
                if (discount.type === 'PERCENTAGE_DISCOUNT_PERMANENT') {
                  this.sokeDiscount = discount;
                }
              }
              this.demand.modules = this.selectedModules;

              if (this.$route.params['token'] && this.$route.params['token'].toLowerCase() !== 'test') {
                this.demand.advertisingToken = this.$route.params['token'];
                this.advertisingOrganization = base64.decode(this.demand.advertisingToken.substr(this.demand.advertisingToken.indexOf('&on=') + 4));
              }

              // Recursive call should run only one time
              this.getPreFilledContract(this.demand);
            }
            this.sending = false;

            this.buildSettlementChart();
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Laden des vorausgefüllten Vertrags');
            this.sending = false;
          });
      },

      concludeContract(demand) {
        this.sending = true;
        this.error = false;
        InfoService.concludeContract(demand, this.organization && this.organization.testPhaseActive)
          .then(response => {
            this.newOrganization = response.data;
            this.contractConcluded = true;
            this.sending = false;

            if (this.organization && this.organization.testPhaseActive && this.newOrganization && !this.newOrganization.testPhaseActive) {
              this.$store.commit('organizationInfo', this.newOrganization);
              this.newOrganizationActivated = true;
            }

            if (this.$refs.recaptcha) {
              this.resetCaptcha();
              this.$refs.recaptcha.reset();
            }
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Schließen des Vertrags');
            this.sending = false;
            this.error = true;

            if (this.$refs.recaptcha) {
              this.resetCaptcha();
              this.$refs.recaptcha.reset();
            }
          });
      },

      requestTestLicense(demand) {
        this.sending = true;
        this.error = false;
        InfoService.concludeContract(demand, false)
          .then(response => {
            this.newOrganization = response.data;
            this.testAccountRequested = true;
            this.sending = false;
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Anlegen der Test-Lizenz');
            this.sending = false;
            this.error = true;
          });
      },

      isSelected(module) {
        return (this.selectedModules.indexOf(module.type) >= 0);
      },

      getSelectedClass(module) {
        if (this.isSelected(module)) {
          return 'selected-color'
        }
        return '';
      },

      selectModule(module) {
        if (module.type === 'DOCUMENTATION_STANDALONE') {
          if (this.selectedModules.indexOf('DOCUMENTATION_STANDALONE') < 0) {
            this.selectedModules = ['DOCUMENTATION_STANDALONE'];
          } else {
            this.selectedModules = ['BASE'];
          }

          this.demand.modules = this.selectedModules;
          this.getPreFilledContract(this.demand);

          return
        }

        if (this.selectedModules.indexOf(module.type) < 0) {
          this.selectedModules.push(module.type)
        } else {
          this.selectedModules.splice(this.selectedModules.indexOf(module.type), 1);
          if(module.type === 'BASE') {
            this.selectedModules = [];
          }
        }

        if(module.type !== 'BASE') {
          if (this.selectedModules.indexOf('DOCUMENTATION_STANDALONE') > -1) {
            this.selectedModules.splice(this.selectedModules.indexOf('DOCUMENTATION_STANDALONE'), 1);
          }
          if (this.selectedModules.indexOf('BASE') < 0) {
            this.selectedModules.push('BASE')
          }
        }

        if(module.type === 'BASE') {
          if (this.selectedModules.indexOf('DOCUMENTATION_STANDALONE') > -1) {
              this.selectedModules.splice(this.selectedModules.indexOf('DOCUMENTATION_STANDALONE'), 1);
          }
        }

        if (this.selectedModules.length <= 0) {
          this.selectedModules = ['BASE'];
        }
        this.demand.modules = this.selectedModules;
        this.getPreFilledContract(this.demand);
      },

      onSelectNumChildren() {
        if (this.demand.numChildren > this.maxNumChildren) {
          this.demand.numChildren = this.maxNumChildren;
        }
        if (this.demand.numChildren > 0 && this.demand.numChildren <= this.maxNumChildren) {
          while (this.demand.numChildren.startsWith('0') && this.demand.numChildren.length > 0) {
            this.demand.numChildren = this.demand.numChildren.substr(1);
          }
          this.getPreFilledContract(this.demand);
        }
      },

      onSelectPaymentInterval() {
        this.getPreFilledContract(this.demand);
      },

      getDiscountFullLabel(discount) {
        if (discount.discountPercentage > 0) {
          return this.labels.paymentInterval[discount.interval] + '  (' + discount.discountPercentage + '%)';
        } else {
          return this.labels.paymentInterval[discount.interval];
        }
      },

      resetDone(id, index) {
        this.error = false;
        this[id] = false;

        // this.secondStepError = null

        if (index) {
          this.activeStep = index;
          window.scrollTo({top: 400, left: 0, behavior: 'smooth'})
        }
      },

      setDone(id, index) {

        let valid = true;

        if (id !== 'firstStep') {
          // Validate second step during second and third step
          this.$refs.registrationForm.validateRegistration();
          this.$refs.addressForm.validateAddress();
          valid = !this.$refs.registrationForm.invalid && !this.$refs.addressForm.invalid;

          if (this.preFilledContract && this.preFilledContract.viewValues.durchschnittMonatErstesJahrBrutto <= 0) {
            this.demand.useSepaForPayment = false;
          }

          if (this.demand.useSepaForPayment) {
            this.$refs.bankAccountForm.validateBankAccount();
            if (valid) {
              valid = !this.$refs.bankAccountForm.invalid;
            }
          }

          this.secondStepInvalid = !valid;
        }

        if (id === 'thirdStep') {
          if (this.secondStepInvalid) {
            // Return to second step
            this.activeStep = 'secondStep';
            this.secondStepError = 'FEHLER: Daten unvollständig!';
            window.scrollTo({top: 400, left: 0, behavior: 'smooth'})
          }
        }

        if (valid) {
          this[id] = true;

          this.secondStepError = null;

          if (index) {
            this.activeStep = index;
            window.scrollTo({top: 400, left: 0, behavior: 'smooth'})
          }

          // Conclude contract
          if (id === 'thirdStep' && this.demand.agbConfirmed && this.demand.dsgvoConfirmed && this.recaptchaResponse.length > 0) {
            this.demand.ai42Contract = this.preFilledContract;
            this.demand.account.mandateReference = this.demand.organizationName + this.demand.organizationType;
            this.concludeContract(this.demand);
          }
        } else {
          this.secondStepError = 'FEHLER: Daten unvollständig!';
        }
      },

      onRequestTestLicense() {
        if (this.demand.testAccountDemand && this.demand.agbConfirmed && this.demand.dsgvoConfirmed && this.recaptchaResponse.length > 0) {

          this.demand.testAccountData.organizationName = this.demand.organizationName;
          this.demand.testAccountData.organizationType = this.demand.organizationType;
          this.demand.testAccountData.email = this.demand.email;
          this.demand.testAccountData.password = this.demand.password;
          this.demand.testAccountData.passwordRepeat = this.demand.passwordRepeat;
          this.demand.testAccountData.advertisingToken = this.demand.advertisingToken;

          this.$v.$touch();
          this.$refs.registrationForm.validateRegistration();

          if (!this.$v.$invalid && !this.$refs.registrationForm.invalid) {
            this.requestTestLicense(this.demand);
          } else {
            window.scrollTo({top: 1050, left: 0, behavior: 'smooth'})
          }
        }

      },
    },

    computed: {
      dataAvailable() {
        return this.organization && this.labels;
      },

      webAppConfig() {
        return this.$store.getters.webAppConfig;
      },

      isAuthenticated() {
        return this.$store.getters.authenticated
      },

      isAdmin() {
        return this.$store.getters.admin
      },

      isSage() {
        return this.$store.getters.sage
      },

      organization() {
        return this.$store.getters.organization
      },

      labels() {
        return this.$store.getters.labels
      },
    }
  }
</script>

<style lang='scss' scoped>
  h5 {
    font-weight: 300;
    margin-bottom: 0;
  }

  .centered {
    position: absolute;
    top: 85%;
    left: 50%;
    right: -40%;
    transform: translate(-35%, -50%);
  }

  h4 {
    font-weight: 300;
  }

  p {
    margin: 0;
  }

  span {
    color: cornflowerblue;
  }

  strong {
    font-weight: 400;
    color: orange;
  }

  .md-textarea {
    font-family: 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
    font-size: 1.3rem;
    line-height: 2rem;
    font-weight: 300;
  }

  .md-title-textarea {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    font-size: 1.2rem;
    line-height: 1.6rem;
    font-weight: 300;
  }

  .md-subtitle-textarea {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    font-size: 0.9rem;
    line-height: 1.2rem;
    font-weight: 300;
  }

  .md-result-textarea {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 300;
  }

  .md-result-textarea b {
    font-weight: 500;
    color: green;
  }

  .md-theme-default .selected-color {
    color: mediumvioletred;
    background: floralwhite;
  }

  .md-theme-dark .selected-color {
    color: orange;
    background: #588668;
  }

  .md-card {
    width: 230px;
    margin: 10px;
    display: inline-block;
    vertical-align: top;
  }

  .md-card .md-subhead  {
    opacity: 0.8;
    font-size: 13px;
  }

  .md-card-header .md-card-media {
    max-height: 0px;
    max-width: 40px;
    margin-left: 0px;
    margin-top: 8px;
    flex: 0 0 80px;
  }

  .md-card-header.md-card-header-flex {
    display: flex;
    justify-content: space-between;
  }

  .md-card-actions {
    background: whitesmoke;
    justify-content: center;
    height: 50px;
  }

  .md-card-actions.selected-color {
    background: antiquewhite;
  }

  .md-tooltip {
    font-size: large;
    text-align: left;
    height: auto;
    max-width: 380px;
    white-space: pre-wrap;
  }

  .md-theme-default .md-tooltip b {
    color: greenyellow;
  }

  .md-theme-dark .md-tooltip b {
    color: mediumvioletred;
  }

  h5 {
    font-weight: 400;
    color: lightseagreen;
  }

  .md-checkbox {
    margin: 8px 8px 8px 0;
  }

  .md-error-checkbox {
    color: red;
    font-size: 12px;
    margin-top: -30px;
    margin-left: 36px;
    position: relative;
    top: 15px;
  }

  .md-steppers {
    padding: 20px 5px;
  }

  .md-content {
    max-height: 120px;
    overflow: auto;
    background: transparent;
  }
</style>
