<template>
  <div v-if="registration" class="grid-x grid-padding-x">
    <div class="cell large-6 medium-6 small-12">
      <md-field :class="getValidationClass('organizationName')">
        <label for="organization-name">Name Organisation / Träger
          <md-tooltip>Diese Angaben können später im System jederzeit geändert werden.</md-tooltip>
        </label>
        <md-input name="organization-name" id="organization-name" autocomplete="off"
                  v-model="registration.organizationName" :disabled="sending"/>
        <span class="md-error" v-if="!$v.registration.organizationName.required">Name ist notwendig</span>
        <span class="md-error" v-else-if="!$v.registration.organizationName.minlength">Ungültiger Name</span>
      </md-field>
    </div>
    <div class="cell large-6 medium-6 small-12">
      <md-field :class="getValidationClass('organizationType')">
        <label>Organisationsform
          <md-tooltip>Diese Angaben können später im System jederzeit geändert werden.</md-tooltip>
        </label>
        <md-select id="organization-type" v-model="registration.organizationType"
                   autocomplete="off"
                   :disabled="sending">
          <md-option v-for="type of labels.organizationTypes" v-bind:value="type" :key="type">
            {{labels.organizationType[type]}}
          </md-option>
        </md-select>
      </md-field>
    </div>
    <div class="cell large-6 medium-6 small-12">
      <md-field :class="getValidationClass('email')">
        <label for="contact-email">Email
          <md-tooltip>Die hier angegebene Email ist Ihre <b>SoSE</b> Benutzer-Kennung.</md-tooltip>
        </label>
        <md-input type="email" name="contact-email" id="contact-email" autocomplete="off"
                  v-model="registration.email" :disabled="!passwordForm || sending"/>
        <span class="md-error"
              v-if="!$v.registration.email.required">Email-Adresse ist notwendig</span>
        <span class="md-error"
              v-else-if="!$v.registration.email.email">Ungültige Email-Adresse</span>
      </md-field>
    </div>
    <div class="cell large-6 medium-12 small-12"></div>
    <div v-if="passwordForm" class="cell large-6 medium-6 small-12">
      <md-field :class="getValidationClass('password')">
        <div class="grid-x" style="width: 100%">
          <div class="cell small-12 medium-12 large-4 text-left">
            <label for="password">Passwort
              <span v-if="passwordScore !== null && passwordScore < 3" style="color: orange; font-size: 12px;"><br>Passwort zu schwach</span>
            </label>
            <md-tooltip>Mit diesem Passwort können Sie sich nach erfolgreicher Registrierung anmelden.</md-tooltip>
          </div>
          <div class="cell small-12 medium-12 large-8 text-right">
            <password id="password" v-model="registration.password" :toggle="true" :secureLength="webAppSettings.pwdSecureLength"  @score="showPasswordScore" style="max-width: 100%;" :disabled="sending"/>
          </div>
        </div>
        <span class="md-error" v-if="!$v.registration.password.required">Passwort ist notwendig</span>
        <span class="md-error" v-else-if="!$v.registration.password.minlength">Passwort muss min. {{webAppSettings.pwdSecureLength}} Zeichen lang sein</span>
      </md-field>
    </div>
    <div v-if="passwordForm" class="cell large-6 medium-6 small-12">
      <md-field :class="getValidationClass('passwordRepeat')">
        <div class="grid-x" style="width: 100%">
          <div class="cell small-12 medium-12 large-4 text-left">
            <label for="passwordRepeat">Passwort<br>wiederholen
              <span v-if="passwordRepeatScore !== null && passwordRepeatScore < 3" style="color: orange; font-size: 12px;"><br>Passwort zu schwach</span>
            </label>
            <md-tooltip>Um Tippfehler auszuschließen wiederholen Sie bitte ihr vergebenes Passwort.</md-tooltip>
          </div>
          <div class="cell small-12 medium-12 large-8 text-right">
            <password id="passwordRepeat" v-model="registration.passwordRepeat" :toggle="true" :secureLength="webAppSettings.pwdSecureLength" @score="showPasswordRepeatScore" style="max-width: 100%;" :disabled="sending"/>
          </div>
        </div>
        <span class="md-error" v-if="!$v.registration.passwordRepeat.sameAsPassword">Passwörter müssen übereinstimmen</span>
      </md-field>
    </div>
  </div>
</template>


<script>
  import {validationMixin} from 'vuelidate'
  import {
    required,
    sameAs,
    email,
    minLength,
  } from 'vuelidate/lib/validators'
  import Password from 'vue-password-strength-meter';

  export default {
    name: 'RegistrationForm',
    mixins: [validationMixin],

    components: { Password },

    props: {
      registration: {
        required: true
      },
      sending: {
        required: true
      },
      passwordForm: {
        required: true
      }
    },

    data: () => ({
      invalid: true,
      passwordScore: null,
      passwordRepeatScore: null,
    }),

    validations: {
      registration: {
        organizationName: {
          required,
          minLength: minLength(3),
        },
        organizationType: {
          required,
        },
        email: {
          required,
          email,
        },
        password: {
          required,
          minLength: minLength(8),
        },
        passwordRepeat: {
          sameAsPassword: sameAs('password')
        }
      },
    },

    methods: {

      showPasswordScore(score) {
        this.passwordScore = score;
      },

      showPasswordRepeatScore(score) {
        this.passwordRepeatScore = score;
      },

      getValidationClass(fieldName) {
        let field = this.$v.registration[fieldName];

        if (field) {
          return {
            'md-invalid': field.$invalid && field.$dirty
          }
        }
      },

      validateRegistration() {
        this.$v.$touch();
        if (this.passwordForm) {
          this.invalid = this.$v.$invalid;
        } else {
          this.invalid = this.validateWithoutPasswords();
        }
      },

      validateWithoutPasswords() {
        return (this.$v.registration.organizationName.$invalid || this.$v.registration.organizationType.$invalid || this.$v.registration.email.$invalid);
      }
    },

    computed: {
      webAppSettings() {
        return this.$store.getters.webAppSettings
      },

      labels() {
        return this.$store.getters.labels
      },
    },
  };
</script>

<style lang="scss" scoped>
  .md-tooltip {
    font-size: large;
    text-align: left;
    height: auto;
    max-width: 380px;
    white-space: pre-wrap;
  }
</style>